import React, {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import { DefaultLayout } from "../../organisms/DefaultLayout";
import * as styles from "../../styles/company/history.module.scss";

// Background
import backgroundTop from "../../images/history/history_topimg.jpg";
import Histoty2005Background from "../../images/history/bg_hirakata002.jpg";
import Histoty2011Background from "../../images/history/service_elumild.jpg";
import Histoty2013Background from "../../images/history/2013.jpeg";
import Histoty2015Background from "../../images/history/evand_210228_24.jpg";
import Histoty2016Background from "../../images/history/_kpo0728_companyoverview.jpg";
import Histoty2017Background from "../../images/history/adobestock_220372535.jpg";
import Histoty2018Background from "../../images/history/bg_2018_tokyo.jpg";
import Histoty2019Background from "../../images/history/bg_2011_artill.jpg";
import Histoty2020Background from "../../images/history/adobestock_88428296.jpg";
import Histoty2021Background from "../../images/history/2021.jpg";
import Histoty2022Background from "../../images/history/2022.png";
// LogoPc
import History2011 from "../../images/history/logo/elumild_white.png";
import History2015 from "../../images/history/logo/evand_white_b.png";
import History2017 from "../../images/history/history001.png";
import History2018Pc from "../../images/history/history002Pc.png";
import History2019One from "../../images/history/history003.png";
import History2019Two from "../../images/history/history004.png";
import History2019Three from "../../images/history/history005.png";
import History2020One from "../../images/history/history009.png";
// LogoSp
import ElumildSp from "../../images/history/logoSp/elumild.png";
import EvandSp from "../../images/history/logoSp/evand.png";
import BestVentureSp from "../../images/history/logoSp/bestVenture100.png";
import FinancialTimesSp from "../../images/history/logoSp/financialTimes.png";
import SuprieveConsultingSp from "../../images/history/logoSp/suprieveConsulting.png";
import AsFineSp from "../../images/history/logoSp/asFine.png";
import ArtillSp from "../../images/history/logoSp/artill.png";
import RegenJapanSp from "../../images/history/logoSp/regenJapan.png";

import { SpHorizontalMenu } from "../../atoms/menu/SpHorizontalMenu";
import { SEO } from "../../atoms/seo/SEO";
import seoImage from "../../images/history/history_topimg.jpg";
import classNames from "classnames";
import FadeIn from "../../atoms/fadein/FadeIn";

const years = [
  "2005",
  "2011",
  "2013",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
];

type HistoryPageProps = {
  id?: string;
  image: "*.png" | "*.jpg" | "*.jpeg";
  isTopPage?: boolean;
};

function startObserve(
  target: Element | null,
  callback: (entry: IntersectionObserverEntry) => void
): void {
  if (!target) {
    return;
  }
  const observer = new IntersectionObserver(entries => {
    for (const entry of entries) {
      callback(entry);
    }
  });
  observer.observe(target);
}

const HistoryPage: FunctionComponent<HistoryPageProps> = ({
  id,
  image,
  isTopPage = false,
  children,
}) => {
  const HistoryPageStyle: CSSProperties = {
    backgroundImage: `url(${image})`,
  };

  return (
    <div
      id={id}
      data-career-page-section-index={id}
      className={classNames(
        styles.CompanyHistory__page,
        isTopPage ? styles.CompanyHistory__pageTop : ""
      )}
      style={HistoryPageStyle}
    >
      <div
        className={classNames(
          styles.CompanyHistory__pageSpImage,
          isTopPage ? styles.CompanyHistory__pageSpImage__top : ""
        )}
        style={HistoryPageStyle}
      />
      <FadeIn>
        <div className={styles.CompanyHistory__pageContent}>{children}</div>
      </FadeIn>
    </div>
  );
};

const HistoryComponent = ({ location: pathname }) => {
  const firstViewRef = useRef<HTMLDivElement | null>(null);
  const [isSecondView, setIsSecondView] = useState(false);
  const [currentAnchorIndex, setCurrentAnchorIndex] = useState("0");

  useEffect(() => {
    startObserve(firstViewRef.current, (entry: IntersectionObserverEntry) => {
      if (firstViewRef.current !== null) {
        setIsSecondView(
          !entry.isIntersecting && entry.boundingClientRect.bottom <= 0
        );
      }
    });
  }, [isSecondView]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setCurrentAnchorIndex(entries[0].target.id);
        }
        if (entries[1] && entries[1].isIntersecting) {
          setCurrentAnchorIndex(entries[1].target.id);
        }
      },
      {
        rootMargin: "-20px",
      }
    );

    const targets = document.querySelectorAll(
      "[data-career-page-section-index]"
    );
    targets.forEach(target => {
      observer.observe(target);
    });
  }, []);

  return (
    <DefaultLayout
      className={styles.CompanyHistory}
      isHistory={true}
      isWhite={!isSecondView}
      isWhiteBackground={isSecondView}
      extraHeader={
        <div
          className={classNames(
            styles.CompanyHistory__navigatorSp,
            isSecondView ? styles.CompanyHistory__navigatorSp_displayed : ""
          )}
        >
          <SpHorizontalMenu className={styles.CompanyHistory__horizontalMenu}>
            {years.map((year, index) => {
              const isCurrent = currentAnchorIndex === (index + 1).toString();
              return (
                <a
                  className={classNames(
                    styles.CompanyHistory__navigatorYearSp,
                    isCurrent
                      ? styles.CompanyHistory__navigatorYearSp_current
                      : ""
                  )}
                  key={`controller-${year}`}
                  href={`#${index + 1}`}
                >
                  {year}
                </a>
              );
            })}
          </SpHorizontalMenu>
        </div>
      }
    >
      <SEO
        pathname={pathname}
        title="ヒストリー | History"
        description="2005年 わくわくエッサ有限会社創業. 2007年 牧野オフィス移転. 2009年樟葉オフィス移転. 2010年 樟葉オフィス増床. 2011年 EC事業部開業. 2013年 Suprieve株式会社名称変更."
        image={seoImage}
      />

      <div
        className={classNames(
          styles.CompanyHistory__navigator,
          isSecondView ? styles.CompanyHistory__navigator_displayed : ""
        )}
      >
        {years.map((year, index) => {
          const isCurrent = currentAnchorIndex === (index + 1).toString();
          return (
            <a
              className={classNames(
                styles.CompanyHistory__navigatorYear,
                isCurrent ? styles.CompanyHistory__navigatorYear_current : ""
              )}
              key={`controller-${year}`}
              href={`#${index + 1}`}
            >
              {year}
            </a>
          );
        })}
      </div>

      <HistoryPage image={backgroundTop} isTopPage={true}>
        <div className={styles.CompanyHistory__title}>
          <h2>History</h2>
          <h3>Suprieveの歩み</h3>
        </div>
      </HistoryPage>

      {/* Intersection Observer 用に仕込む */}
      <div ref={firstViewRef} />

      <HistoryPage id="1" image={Histoty2005Background}>
        <div
          className={classNames(
            styles.CompanyHistory__section,
            styles.CompanyHistory__firstTypeSection
          )}
        >
          <div className={styles.CompanyHistory__sectionLineSp} />
          <div className={styles.CompanyHistory__sectionItem}>
            <h2 className={styles.CompanyHistory__sectionItemYear}>2005</h2>
            <p className={styles.CompanyHistory__sectionItemText}>
              わくわくエッサ有限会社創業
            </p>
            <p className={styles.CompanyHistory__sectionItemTextPrallel}>
              イベント事業部創業
            </p>
          </div>
          <div className={styles.CompanyHistory__sectionItem}>
            <h2 className={styles.CompanyHistory__sectionItemYear}>2007</h2>
            <p className={styles.CompanyHistory__sectionItemText}>
              牧野オフィス移転
            </p>
          </div>
          <div className={styles.CompanyHistory__sectionItem}>
            <h2 className={styles.CompanyHistory__sectionItemYear}>2009</h2>
            <p className={styles.CompanyHistory__sectionItemText}>
              樟葉オフィス移転
            </p>
          </div>
          <div className={styles.CompanyHistory__sectionItem}>
            <h2 className={styles.CompanyHistory__sectionItemYear}>2010</h2>
            <p className={styles.CompanyHistory__sectionItemText}>
              樟葉オフィス増床
            </p>
          </div>
        </div>
      </HistoryPage>

      <HistoryPage id="2" image={Histoty2011Background}>
        <div
          className={classNames(
            styles.CompanyHistory__section,
            styles.CompanyHistory__secondTypeSection
          )}
        >
          <div className={styles.CompanyHistory__sectionLineSp} />
          <h2 className={styles.CompanyHistory__sectionItemYear}>2011</h2>
          <div className={styles.CompanyHistory__sectionItemUnderYear}>
            <img
              className={styles.CompanyHistory__sectionItemImage_sp}
              src={ElumildSp}
              alt=""
            />
            <p className={styles.CompanyHistory__sectionItemText}>
              EC事業部開業
            </p>
            <img
              className={classNames(
                styles.CompanyHistory__sectionItemImage,
                styles.CompanyHistory__sectionItemImage_pc
              )}
              src={History2011}
              alt=""
            />
          </div>
        </div>
      </HistoryPage>

      <HistoryPage id="3" image={Histoty2013Background}>
        <div
          className={classNames(
            styles.CompanyHistory__section,
            styles.CompanyHistory__secondTypeSection
          )}
        >
          <div className={styles.CompanyHistory__sectionLineSp} />
          <h2 className={styles.CompanyHistory__sectionItemYear}>2013</h2>
          <div className={styles.CompanyHistory__sectionItemUnderYear}>
            <h3 className={styles.CompanyHistory__sectionItemText}>
              Suprieve 株式会社&nbsp;&nbsp;名称変更
            </h3>
          </div>
        </div>
      </HistoryPage>

      <HistoryPage id="4" image={Histoty2015Background}>
        <div
          className={classNames(
            styles.CompanyHistory__section,
            styles.CompanyHistory__secondTypeSection
          )}
        >
          <div className={styles.CompanyHistory__sectionLineSp} />
          <h2 className={styles.CompanyHistory__sectionItemYear}>2015</h2>
          <div className={styles.CompanyHistory__sectionItemMultipleUnderYear}>
            <h3 className={styles.CompanyHistory__sectionItemText}>
              メディア事業部開業
            </h3>

            <h3 className={styles.CompanyHistory__sectionItemText}>
              <img
                src={EvandSp}
                alt=""
                className={styles.CompanyHistory__sectionItemImage_sp}
              />
              <span className={styles.CompanyHistory__sectionItemTextBody}>
                人材事業部開業
              </span>
              <img
                className={classNames(
                  styles.CompanyHistory__sectionItemImage,
                  styles.CompanyHistory__sectionItemImage_pc,
                  styles.CompanyHistory__sectionItemImage2015
                )}
                src={History2015}
                alt=""
              />
            </h3>
          </div>
        </div>
      </HistoryPage>

      <HistoryPage id="5" image={Histoty2016Background}>
        <div
          className={classNames(
            styles.CompanyHistory__section,
            styles.CompanyHistory__secondTypeSection
          )}
        >
          <div className={styles.CompanyHistory__sectionLineSp} />
          <h2 className={styles.CompanyHistory__sectionItemYear}>2016</h2>
          <div className={styles.CompanyHistory__sectionItemMultipleUnderYear}>
            <h3 className={styles.CompanyHistory__sectionItemText}>
              広告事業部開業
            </h3>
            <h3 className={styles.CompanyHistory__sectionItemText}>
              梅田オフィスへ増床移転
            </h3>
          </div>
        </div>
      </HistoryPage>

      <HistoryPage id="6" image={Histoty2017Background}>
        <div
          className={classNames(
            styles.CompanyHistory__section,
            styles.CompanyHistory__secondTypeSection
          )}
        >
          <div className={styles.CompanyHistory__sectionLineSp} />
          <h2 className={styles.CompanyHistory__sectionItemYear}>2017</h2>
          <div className={styles.CompanyHistory__sectionItemMultipleUnderYear}>
            <h3 className={styles.CompanyHistory__sectionItemText}>
              ベストベンチャー100に選出
            </h3>
            <img
              className={classNames(
                styles.CompanyHistory__sectionItemImage_pc,
                styles.CompanyHistory__sectionItemImage2017
              )}
              src={History2017}
              alt=""
            />
            <img
              className={classNames(
                styles.CompanyHistory__sectionItemImage_sp,
                styles.CompanyHistory__sectionItemImage2017
              )}
              src={BestVentureSp}
              alt=""
            />
          </div>
        </div>
      </HistoryPage>

      <HistoryPage id="7" image={Histoty2018Background}>
        <div
          className={classNames(
            styles.CompanyHistory__section,
            styles.CompanyHistory__thirdTypeSection
          )}
        >
          <div className={styles.CompanyHistory__sectionLineSp} />
          <div className={styles.CompanyHistory__sectionItem2018}>
            <p className={styles.CompanyHistory__sectionItemYear}>2018</p>
            <p
              className={`${styles.CompanyHistory__sectionItemText} ${styles.CompanyHistory__sectionItemText2018}`}
            >
              第２創業期スタート
            </p>
          </div>

          <div
            className={`${styles.CompanyHistory__sectionItemMultipleUnderYear} ${styles.CompanyHistory__sectionItem2018}`}
          >
            <p className={styles.CompanyHistory__sectionItemText}>
              Financial Times 1000
              <br />
              high-growth compainies Asia-Pacific
            </p>
            <img
              className={styles.CompanyHistory__sectionItemImage2018}
              src={History2018Pc}
              alt=""
            />
            <img
              className={styles.CompanyHistory__sectionItemImage2018Sp}
              src={FinancialTimesSp}
              alt=""
            />
            <div className={styles.CompanyHistory__sectionItemTexts}>
              <h3 className={styles.CompanyHistory__sectionItemText}>
                東京オフィス開設
              </h3>
              <h3 className={styles.CompanyHistory__sectionItemText}>
                イベント事業売却
              </h3>
            </div>
          </div>
        </div>
      </HistoryPage>

      <HistoryPage id="8" image={Histoty2019Background}>
        <div
          className={classNames(
            styles.CompanyHistory__section,
            styles.CompanyHistory__firstTypeSection
          )}
        >
          <div className={styles.CompanyHistory__sectionLineSp} />
          <h2 className={styles.CompanyHistory__sectionItemYear}>2019</h2>

          <div
            className={classNames(
              styles.CompanyHistory__sectionItemUnderYear,
              styles.CompanyHistory__sectionItemUnderYear2019
            )}
          >
            <img
              src={SuprieveConsultingSp}
              alt=""
              className={classNames(
                styles.CompanyHistory__sectionItemImage_sp,
                styles.CompanyHistory__sectionItemImage2019
              )}
            />
            <h3
              className={`${styles.CompanyHistory__sectionItemText} ${styles.CompanyHistory__sectionItemText2019}`}
            >
              Suprieve Consulting株式会社創立
            </h3>
            <img
              className={classNames(
                styles.CompanyHistory__sectionItemImage_pc,
                styles.CompanyHistory__sectionItemImage2019
              )}
              src={History2019One}
              alt=""
            />
          </div>

          <div
            className={`${styles.CompanyHistory__sectionItemUnderYear} ${styles.CompanyHistory__sectionItemUnderYear2019}`}
          >
            <img
              src={AsFineSp}
              alt=""
              className={classNames(
                styles.CompanyHistory__sectionItemImage_sp,
                styles.CompanyHistory__sectionItemImage2019
              )}
            />
            <h3
              className={`${styles.CompanyHistory__sectionItemText} ${styles.CompanyHistory__sectionItemText2019}`}
            >
              Asfine株式会社創立
            </h3>
            <img
              className={classNames(
                styles.CompanyHistory__sectionItemImage_pc,
                styles.CompanyHistory__sectionItemImage2019
              )}
              src={History2019Two}
              alt=""
            />
          </div>

          <div
            className={classNames(
              styles.CompanyHistory__sectionItemUnderYear,
              styles.CompanyHistory__sectionItemUnderYear2019
            )}
          >
            <img
              src={ArtillSp}
              alt=""
              className={classNames(
                styles.CompanyHistory__sectionItemImage_sp,
                styles.CompanyHistory__sectionItemImage2019
              )}
            />
            <h3
              className={classNames(
                styles.CompanyHistory__sectionItemText,
                styles.CompanyHistory__sectionItemText2019
              )}
            >
              Artill株式会社創立
            </h3>
            <img
              className={classNames(
                styles.CompanyHistory__sectionItemImage_pc,
                styles.CompanyHistory__sectionItemImage2019
              )}
              src={History2019Three}
              alt=""
            />
          </div>

          <div
            className={classNames(
              styles.CompanyHistory__sectionItemUnderYear,
              styles.CompanyHistory__sectionItemUnderYear2019,
              styles.CompanyHistory__sectionItemUnderYearOnlyText
            )}
          >
            <h3
              className={classNames(
                styles.CompanyHistory__sectionItemText,
                styles.CompanyHistory__sectionItemText2019
              )}
            >
              福岡オフィス開設
            </h3>
          </div>

          <div
            className={classNames(
              styles.CompanyHistory__sectionItemUnderYear,
              styles.CompanyHistory__sectionItemUnderYear2019
            )}
          >
            <h3
              className={classNames(
                styles.CompanyHistory__sectionItemText,
                styles.CompanyHistory__sectionItemText2019
              )}
            >
              ホワイト企業認定 シルバー取得
            </h3>
          </div>
        </div>
      </HistoryPage>

      <HistoryPage id="9" image={Histoty2020Background}>
        <div
          className={classNames(
            styles.CompanyHistory__section,
            styles.CompanyHistory__thirdTypeSection
          )}
        >
          <div className={styles.CompanyHistory__sectionLineSp} />
          <h2 className={styles.CompanyHistory__sectionItemYear}>2020</h2>
          <div
            className={classNames(
              styles.CompanyHistory__sectionItemUnderYear,
              styles.CompanyHistory__sectionItemUnderYear2019
            )}
          >
            <img
              className={classNames(
                styles.CompanyHistory__sectionItemImage_sp,
                styles.CompanyHistory__sectionItemImage2020
              )}
              src={RegenJapanSp}
              alt=""
            />
            <h3
              className={classNames(
                styles.CompanyHistory__sectionItemText,
                styles.CompanyHistory__sectionItemText2019
              )}
            >
              Regen Japan株式会社
            </h3>
            <img
              className={classNames(
                styles.CompanyHistory__sectionItemImage_pc,
                styles.CompanyHistory__sectionItemImage2020
              )}
              src={History2020One}
              alt=""
            />
          </div>
          <div
            className={classNames(
              styles.CompanyHistory__sectionItemUnderYear,
              styles.CompanyHistory__sectionItemUnderYear2019,
              styles.CompanyHistory__sectionItemUnderYearOnlyText
            )}
          >
            <h3
              className={classNames(
                styles.CompanyHistory__sectionItemText,
                styles.CompanyHistory__sectionItemText2019
              )}
            >
              ファンド事業部開業
            </h3>
          </div>

          <div
            className={classNames(
              styles.CompanyHistory__sectionItemUnderYear,
              styles.CompanyHistory__sectionItemUnderYear2019
            )}
          >
            <h3
              className={classNames(
                styles.CompanyHistory__sectionItemText,
                styles.CompanyHistory__sectionItemText2019
              )}
            >
              名古屋オフィス開設
            </h3>
          </div>
          <div
            className={classNames(
              styles.CompanyHistory__sectionItemUnderYear,
              styles.CompanyHistory__sectionItemUnderYear2019
            )}
          >
            <h3
              className={classNames(
                styles.CompanyHistory__sectionItemText,
                styles.CompanyHistory__sectionItemText2019
              )}
            >
              シンガポールオフィス開設
            </h3>
          </div>
        </div>
      </HistoryPage>

      <HistoryPage id="10" image={Histoty2021Background}>
        <div
          className={`${styles.CompanyHistory__section} ${styles.CompanyHistory__thirdTypeSection}`}
        >
          <div className={styles.CompanyHistory__sectionLineSp} />
          <div className={styles.CompanyHistory__sectionItem2018}>
            <h2 className={styles.CompanyHistory__sectionItemYear}>2021</h2>
          </div>
          <div
            className={classNames(
              styles.CompanyHistory__sectionItemMultipleUnderYear,
              styles.CompanyHistory__sectionItem2018
            )}
          >
            <p
              className={classNames(
                styles.CompanyHistory__sectionItemText,
                styles.CompanyHistory__sectionItemText2018
              )}
            >
              ホワイト企業認定 ゴールド取得
            </p>
          </div>
          <div
            className={classNames(
              styles.CompanyHistory__sectionItemMultipleUnderYear,
              styles.CompanyHistory__sectionItem2018
            )}
          >
            <p
              className={classNames(
                styles.CompanyHistory__sectionItemText,
                styles.CompanyHistory__sectionItemText2018
              )}
            >
              Suprieve本社をグランフロントビルに移転
            </p>
          </div>
          <div
            className={classNames(
              styles.CompanyHistory__sectionItemMultipleUnderYear,
              styles.CompanyHistory__sectionItem2018
            )}
          >
            <p
              className={classNames(
                styles.CompanyHistory__sectionItemText,
                styles.CompanyHistory__sectionItemText2018
              )}
            >
              札幌オフィス開設
            </p>
          </div>
        </div>
      </HistoryPage>

      <HistoryPage id="11" image={Histoty2022Background}>
        <div
          className={classNames(
            styles.CompanyHistory__section,
            styles.CompanyHistory__thirdTypeSection
          )}
        >
          <div className={styles.CompanyHistory__sectionLineSp} />
          <div className={styles.CompanyHistory__sectionItem2018}>
            <h2 className={styles.CompanyHistory__sectionItemYear}>2022</h2>
          </div>
          <div
            className={classNames(
              styles.CompanyHistory__sectionItemMultipleUnderYear,
              styles.CompanyHistory__sectionItem2018
            )}
          >
            <p
              className={classNames(
                styles.CompanyHistory__sectionItemText,
                styles.CompanyHistory__sectionItemText2018
              )}
            >
              GLOBAL FRONT INVESTMENT株式会社創立
            </p>
          </div>
        </div>
      </HistoryPage>
    </DefaultLayout>
  );
};

export default () => <HistoryComponent />;
