// extracted by mini-css-extract-plugin
export var CompanyHistory = "j_b5";
export var CompanyHistory__navigatorSp = "j_b6";
export var CompanyHistory__navigatorSp_displayed = "j_b7";
export var CompanyHistory__horizontalMenu = "j_b8";
export var CompanyHistory__navigatorYearSp = "j_b9";
export var CompanyHistory__navigatorYearSp_current = "j_cb";
export var CompanyHistory__navigator = "j_cc";
export var CompanyHistory__navigator_displayed = "j_cd";
export var CompanyHistory__navigatorYear = "j_cf";
export var CompanyHistory__navigatorYear_current = "j_cg";
export var CompanyHistory__navigatorCurrentYearIndicator = "j_ch";
export var CompanyHistory__page = "j_cj";
export var CompanyHistory__pageTop = "j_ck";
export var CompanyHistory__pageSpImage = "j_cl";
export var CompanyHistory__pageSpImage__top = "j_cm";
export var CompanyHistory__pageContent = "j_cn";
export var CompanyHistory__title = "j_cp";
export var CompanyHistory__firstSectionImage = "j_cq";
export var CompanyHistory__firstSectionImageWrap = "j_cr";
export var CompanyHistory__firstSectionImageNowrap = "j_cs";
export var CompanyHistory__firstSectionImageJustifyBetween = "j_ct";
export var CompanyHistory__imageBodyPc = "j_cv";
export var CompanyHistory__imageBodySp = "j_cw";
export var CompanyHistory__section = "j_cx";
export var child = "j_cy";
export var show = "j_cz";
export var CompanyHistory__sectionLineSp = "j_cB";
export var CompanyHistory__cover = "j_cC";
export var CompanyHistory__coverAnimation = "j_cD";
export var CompanyHistory__down = "j_cF";
export var CompanyHistory__firstTypeSection = "j_cG";
export var CompanyHistory__sectionItem = "j_cH";
export var CompanyHistory__sectionItem2018 = "j_cJ";
export var CompanyHistory__sectionItemYear = "j_cK";
export var CompanyHistory__sectionItemText = "j_cL";
export var CompanyHistory__sectionItemText2019 = "j_cM";
export var CompanyHistory__sectionItemTextPrallel = "j_cN";
export var CompanyHistory__secondTypeSection = "j_cP";
export var CompanyHistory__sectionItemUnderYear = "j_cQ";
export var CompanyHistory__sectionItemUnderYear2019 = "j_cR";
export var CompanyHistory__sectionItemTextLarge = "j_cS";
export var CompanyHistory__sectionItemImage = "j_cT";
export var CompanyHistory__sectionItemImage_pc = "j_cV";
export var CompanyHistory__sectionItemImage_sp = "j_cW";
export var CompanyHistory__sectionItemImage2015 = "j_cX";
export var CompanyHistory__sectionItemImage2017 = "j_cY";
export var CompanyHistory__sectionItemImage2018 = "j_cZ";
export var CompanyHistory__sectionItemImage2019 = "j_c0";
export var CompanyHistory__sectionItemImage2019Large = "j_c1";
export var CompanyHistory__sectionItemImage2020 = "j_c2";
export var CompanyHistory__sectionItemTextBody = "j_c3";
export var CompanyHistory__sectionItemMultipleUnderYear = "j_c4";
export var CompanyHistory__thirdTypeSection = "j_c5";
export var CompanyHistory__sectionItemText2018 = "j_c6";
export var CompanyHistory__sectionItemImage2018Sp = "j_c7";
export var CompanyHistory__sectionItemTexts = "j_c8";
export var CompanyHistory__sectionItemImage2021Sp = "j_c9";
export var CompanyHistory__sectionItemUnderYearOnlyText = "j_db";
export var CompanyHistory__footer = "j_dc";